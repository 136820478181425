import React from 'react';
import Top from "./parts/Top";

import LinkButton from "./parts/LinkButton";
import {useNavigate} from "react-router-dom";
import {dataFetch} from "./utils/lib";
import RiveLoad from "./parts/RiveLoad";
import {Trans, useTranslation} from "react-i18next";
import {trackSuccess} from "./utils/tracking";
import {BookEnum} from "./_business/BookEnum";

export default function Success({step}) {
  const
    {t} = useTranslation('common', {keyPrefix: 'success'}),
    navigate = useNavigate()

  const
    uid = window.localStorage.getItem('uid'),
    [data, setData] = React.useState({})
    // {data} = useData(`web-members/info/${uid}`, {
    //   transaction_id: window.localStorage.getItem('transaction_id')
    // })

  React.useEffect(() => {
    if (!uid) {
      navigate('/')
      return
    }

    dataFetch(
      `${process.env.REACT_APP_API_URI}web-members/info/${uid}`,
      {
        transaction_id: window.localStorage.getItem('transaction_id')
      },
      (response) => setData(response)
    )

    // setStep({
    //   ...step,
    //   // product: window.localStorage.getItem('product') || 'noname'
    // })

    window.sessionStorage.setItem('success', '1')

    trackSuccess(
      window.localStorage.getItem('transaction_id')
    )
  }, [step])

  const bookType = window.sessionStorage.getItem('book_type') || BookEnum.bundle

  const handleBookClick = (e) => {
    if (
      bookType.includes('bundle') &&
      !window.sessionStorage.getItem('book_success')
    )
      e.target.href = `${process.env.REACT_APP_WEB_LINK}book/bundle/`
  }

  return (
    <div className={'wrap'}>
      <Top type={'center'}>
        <h1 className={'h2'}>{t('h1')}</h1>

        <RiveLoad width={350} height={200} src={'success.riv'} stateMachines="Paywall_Success_v2"/>

        <h2 className={'h3'}>{t('h2')}</h2>

        {
          data.password &&
          <p className={'small'}>
            <Trans i18nKey="description" t={t}>
              Please check the spelling of the email to ensure it is correct. If there are any errors, please contact our
              <a rel="noreferrer" href={'https://help.vocalimage.app/'} target={'_blank'}
                 style={{color: '#345CE7', fontWeight: 'bold', textDecoration: 'underline'}}>support team</a>
              <em className={'nowrap'}>to have it fixed</em>.
            </Trans>
          </p>
        }

        <ol className={'list text-start'}>
          <li className={'notice'}>{t('first')}</li>
          <li>
            <Trans i18nKey="second" t={t}>
              Log In <em className={'notice'}>in the app</em>
            </Trans>
          </li>
        </ol>

        <div className={'text-start w-100 items'} style={{
          padding: '0.75rem 0.75rem 0.75rem 1.25rem',
          gap: '0.625rem',
          borderRadius: 'var(--radius)',
          border: '1px solid var(--bg-50)',
          overflowWrap: 'anywhere'}}>
          <p className={'w-100'}>
            <Copy text={data.email}/>
            <Trans i18nKey="email" t={t}>
              <em className={'note xl'}>Email:</em> <em className={'notice'}>{{email: data.email || ''}}</em>
            </Trans>
          </p>
          {
            data.password &&
            <p className={'w-100'}>
              <Copy text={data.password}/>
              <Trans i18nKey="password" t={t}>
                <em className={'note xl'}>Password:</em> <em className={'notice'}>{{password: data.password || ''}}</em>
              </Trans>
            </p>
          }
        </div>

        {
          data.password &&
          <p className={'note lg'}>{t('note')}</p>
        }

        <LinkButton target={'_blank'} to={'https://install.vocalimage.info/install?utm_campaign=w2w'}>{t('download')}</LinkButton>

        <LinkButton target={'_blank'} variant={'warning'} onClick={handleBookClick}
                    to={`${process.env.REACT_APP_WEB_LINK}book-download/${uid}?type=${bookType}`}
        >
          {t(bookType.includes('bundle') ? 'downloadBooks' : 'downloadBook')}
        </LinkButton>

        <hr/>

        <div className={'paragraph'}>
          <h3>{t('questions')}</h3>
          <p className={'note lg'}>
            <Trans i18nKey="helpCenter" t={t}>
              Check out our <a rel="noreferrer" href={'https://help.vocalimage.app/'} target={'_blank'} style={{color: 'var(--primary-100)', fontWeight: 'bold'}}>Help Center</a>
            </Trans>
          </p>
          <p className={'note lg'}>
            <Trans i18nKey="contactUs" t={t}>
              Contact us at <a href={'mailto:support@vocalimage.app'} className={'link'}>support@vocalimage.app</a>
            </Trans>
          </p>
        </div>

        <div className={'row w-auto'}>
          <a rel="noreferrer" href={process.env.REACT_APP_IOS_LINK} target={'_blank'}><img src={require('./assets/img/paywall/app-store.png')} width={132} height={34} alt={'App Store'}/></a>
          <a rel="noreferrer" href={process.env.REACT_APP_GOOGLE_LINK} target={'_blank'}><img src={require('./assets/img/paywall/google-store.png')} width={132} height={34} alt={'Google Play'}/></a>
        </div>
      </Top>
    </div>
  )
}

function Copy({text = ''}) {
  const {t} = useTranslation()

  const [notice, setNotice] = React.useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(text).then(() => {
      setNotice(true)
      setTimeout(() => setNotice(false), 3000)
    })
  }

  return (
    <span style={{float: 'right', top: 1, position: 'relative'}}>
      {
        notice &&
        <em className={'small-sm nowrap panel'}
            style={{position: 'absolute', top: -5, left: '50%', transform: 'translate(-50%)'}}>{t('copied')}</em>
      }
      <img role={'button'} onClick={handleClick} width={17} height={20} src={require('./assets/img/icon/copy.svg').default} alt={'copy'}/>
    </span>
  )
}
