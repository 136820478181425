import React from 'react';
import Top from "./parts/Top";
import Plan from "./parts/paywall/Plan";

import {useNavigate, useParams} from "react-router-dom";
import Info from "./parts/paywall/Info";
import Payment from "./parts/paywall/Payment";
import {Trans, useTranslation} from "react-i18next";
import {setPrice} from "./utils/lib";

function Timer({id, time}) {
  return (
    <strong id={id}>{Math.floor(time / 60)}:{time % 60 < 10 ? 0 : ''}{time % 60}</strong>
  )
}

function TimerPanel() {
  const
    {t} = useTranslation('common', {keyPrefix: 'discount'}),
    navigate = useNavigate()

  const [time, setTime] = React.useState(10 * 60)

  React.useEffect(() => {
    window.timer = setInterval(() => {
      setTime(time => {
        if (time <= 0) {
          // mixpanel.track('discount is out')
          clearInterval(window.timer)
          navigate('/paywall', {replace: true})
        }

        return time - 1
      })
    }, 1000)

    return () => clearInterval(window.timer)
  }, [])

  if (time <= 0)
    return

  return (
    <div className={'block text-center'}
         style={{position: 'absolute', right: 0, top: 0, width: 'auto', padding: '0.5rem 1rem'}}>
      <Trans i18nKey="expires" t={t}>
        <p className={'notice sm'}>Expires in:</p>
        <Timer id={'time'} time={time}/>
      </Trans>
    </div>
  )
}

export default function Discount() {
  const {uid} = useParams()

  const [active, setActive] = React.useState()

  React.useEffect(() => {
    window.localStorage.setItem('uid', uid)
    // mixpanel.track_pageview({page: 'discount'})
  }, [])

  React.useEffect(() => {
    if (active)
      setPrice(active)
  }, [active])

  return (
    <div className={'wrap'}>
      <Top className={'gap-3'}>
        <div className={'block items'}>
          <div style={{marginBottom: '-1.25rem', position: 'relative'}}>
            <TimerPanel/>
            <img src={require('./assets/img/paywall/discount.png')} alt="" width={390} height={298} className={'mw-100'}/>
          </div>
          <Plan path={'discount'} discount={true} onChange={item => setActive(item)} />
        </div>

        <Info/>
        <Payment data={active} nextUrl={'/book'}/>
      </Top>
    </div>
  )
}
