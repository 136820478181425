export const LandingEnum = Object.freeze({
  quiz: 'quiz',
  masculinity: 'masculinity',
  quizMasculinity: 'quiz-masculinity',
  web: 'web',
  dash: 'dash',
  accent: 'accent',
  archetype: 'archetype',
  fellowers: 'fellowers',
  eppc: 'eppc',
})
