import React from 'react';
// import {useNavigate} from "react-router-dom";

export const Slide = ({index, children, className, style}) => {
  return (
    <div id={'slide-' + index} className={'slide' + (className ? ' ' + className : '')} style={style}>
      {children}
    </div>
  )
}

export function Slider({view = false, children, time, pStyle, onSlide}) {
  const
    // navigate = useNavigate(),
    count = React.Children.count(children),
    [activeIndex, setActiveIndex] = React.useState(0),
    slidesRef = React.useRef()
    // hash = window.location.hash.replace('#', '')

  let timer

  React.useEffect(() => {
    document.head.insertAdjacentHTML('beforeend',
`<style>
.slider { overflow: hidden; width: 100% }
.slides { display: flex; gap: 12px; align-items: stretch; overflow-x: auto; scroll-snap-type: x mandatory; scroll-behavior: smooth; -webkit-overflow-scrolling: touch; -ms-overflow-style: none; scrollbar-width: none }
.slide { scroll-snap-align: center; flex-shrink: 0; transform-origin: center center; transform: scale(1); transition: transform 0.5s; position: relative; width: 100% }

.view { width: calc(100% + 48px) }
/*.view .slides { padding: 0 24px }*/
/*.view .slides { justify-content: center; padding: 0 24px }*/
.view .slide { width: calc(100% - 48px) }
.view .slide:first-child { width: calc(100% - 24px); padding-left: 24px }
.view .slide:last-child { width: calc(100% - 24px); padding-right: 24px }
/*.view .slide { padding: 0 24px }*/

.slides::-webkit-scrollbar { width: 0; display: none }
.slides::-webkit-scrollbar-thumb { background: transparent; border: 0 }
.slides::-webkit-scrollbar-track { background: transparent }

.slider .points { display: flex; justify-content: center; align-items: flex-start }
.slider .points a { padding: 0.25rem }
.slider .points i { display: block;  width: 0.5rem; height: 0.5rem; border-radius: 50%; background-color: var(--bg-50) }
.slider .points i.active { background-color: var(--white-100) }
</style>`
    )
  }, [])

  React.useEffect(() => {
    if (time) {
      timer = setInterval(() => {
        setActiveIndex(index => {
          let newIndex

          if (index + 1 < count)
            newIndex = index + 1
          else
            newIndex = 0

          slidesRef.current.scrollLeft = newIndex * slidesRef.current.offsetWidth

          return newIndex
        });
      }, time)

      return () => clearInterval(timer)
    }
  }, [time])

  React.useEffect(() => {
    if (onSlide)
      onSlide(activeIndex)
  }, [activeIndex])

  // React.useEffect(() => {
  //   // checkHash()
  //
  //   if (!hash)
  //     return
  //
  //   document.getElementById(hash)
  //     // .scrollIntoView({block: 'start', behavior: 'instant'})
  //     .scrollIntoView({block: 'start', behavior: 'smooth'})
  // }, [hash])

  const
    handleScroll = (e) => {
      setActiveIndex(Math.round(e.target.scrollLeft / e.target.offsetWidth))
    },
    handlePoint = (i, e) => {
      e.preventDefault()

      // navigate(`#${e.target.closest('a').href.split('#')[1]}`)

      document.getElementById(e.target.closest('a').href.split('#')[1])
        .scrollIntoView({block: 'start', behavior: 'smooth'})

      // scrollIntoViewWithOffset(e.target.closest('a').getAttribute('href'), 24, 'left')

      // document.querySelector('.slides').scrollTo({
      //   behavior: 'smooth',
      //   left:
      //     -200
      //     // document.querySelector().getBoundingClientRect().left -
      //     // document.body.getBoundingClientRect().left -
      //     // 24,
      // })
    }

  return (
    <div className={view ? 'view' : 'w-100'}>
      <div className={'slider'}>
        <div ref={slidesRef} className="slides mb-4" onScroll={handleScroll}>
          {children}
        </div>

        <div className={'points'} style={pStyle}>
          {Array.from(Array(count), (e, i) => {
            return (
              <a key={i} href={'#slide-' + i} aria-label={'Slide ' + i} onClick={handlePoint.bind(this, i)}>
                <i className={(activeIndex === i ? 'active' : '')} />
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}
