import React from 'react';
import Bottom from "./parts/Bottom";
import Top from "./parts/Top";
import Button from "./parts/Button";
import {useNavigate} from "react-router-dom";
import {dataFetch, getItem, getResults, getSurveyData, identify, setSession, setStep} from "./utils/lib";

import Logo from "./parts/Logo";

import {useTranslation, Trans} from "react-i18next";
import RiveLoad from "./parts/RiveLoad";
import Cookies from "js-cookie";
import emailDomains from '../src/assets/domains.json'

export default function SignIn ({step, toAdd, nextUrl, preload}) {
  const
    {t, i18n} = useTranslation(),
    navigate = useNavigate()

  const
    surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage),
    // surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true, lng: 'en'}), i18n.resolvedLanguage)
    resultList = getItem('survey_results'),
    inputRef = React.useRef()

  const
    // A5 = getExperiment('A5'),
    // A8 = window.landing === 'masculinity' ? {value: 'A'} : getExperiment('A8'),
    // A44 = getExperiment('A44'),
    [isLoading, setIsLoading] = React.useState(false),
    [validated, setValidated] = React.useState(false),
    [email, setEmail] = React.useState(''),
    [suggestions, setSuggestions] = React.useState([])

  React.useEffect(() => {
    if (resultList.length < surveyList.length) {
      navigate('/survey', {replace: true})
      return
    }

    if (!window.localStorage.getItem('program_results')) {
      navigate('/loading', {replace: true})
      return
    }

    // if (A44.value === 'A')
    //   return

    const styles = document.createElement("style")
    styles.innerHTML =
`.suggestions { position: absolute; list-style: none; height: auto; max-height: 242px; overflow-y: auto; margin: -18px 0 0 0; padding: 0 }
.suggestions li { padding: 0.5rem 1.25rem; cursor: pointer; margin: 0; text-align: left }
.suggestions li:hover { color: var(--white-100); background-color: var(--bg-100) }`
    document.head.appendChild(styles)

    // setStep(step, toAdd)
  }, [])

React.useEffect(() => {
  // if (A44.value === 'A')
  //   return

  const handleKeyDown = (e) => {
    if (e.key === 'Escape')
      setSuggestions([])
  }

  const handleClickOutside = (e) => {
    if (
      suggestions.length > 0 &&
      !document.querySelector('.suggestions')?.contains(e.target)
    )
      setSuggestions([])
  }

  document.addEventListener('keydown', handleKeyDown)
  document.addEventListener('mousedown', handleClickOutside)
  document.addEventListener('touchstart', handleClickOutside)

  return () => {
    document.removeEventListener('keydown', handleKeyDown)
    document.removeEventListener('mousedown', handleClickOutside)
    document.removeEventListener('touchstart', handleClickOutside)
  }
}, [suggestions])

const validateEmail = (email) => {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  function handleChange(e) {
    const value = e.target.value

    setEmail(value)
    setValidated(false)

    // if (A44.value === 'A')
    //   return

    const [localPart, domainPart] = value.split('@')

    if (domainPart !== undefined) {
      const suggestionList =
        domainPart.length >= 1
          ? emailDomains.filter((domain) => domain.startsWith(domainPart) && domain !== domainPart).map((domain) => `${localPart}@${domain}`)
          : ['gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'msn.com', 'proton.me'].map((domain) => `${localPart}@${domain}`)

      setSuggestions(suggestionList)
    } else
      setSuggestions([])
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (isLoading)
      return

    if (!validateEmail(email)) {
      alert(t('signIn.checkEmail'))
      return
    }

    if (email.endsWith('.con') && !validated) {
      setValidated(true)

      if (window.confirm(t('signIn.checkEmail2')))
        setEmail(email.replace(/\.con$/, '.com'))

      return
    }

    const getFbp = () => {
        let result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie)

        if (!(result && result[1]))
          return null

        return result[1]
    }

    const getFpc = () => {
        let result = /_fbc=(fb\.1\.\d+\.\S+)/.exec(window.document.cookie)

        if (!(result && result[1]))
          return null

        return result[1].replace(';', '')
    }

    setIsLoading(true)

    const data = {
      lang: i18n.resolvedLanguage,
      age: window.localStorage.getItem('age'),
      email,
      fbp: getFbp(),
      fbc: getFpc(),
      positionList: resultList,
      resultList: getResults(surveyList, resultList),
      positionProgramList: getItem('program_results'),
      session_uid: (window.session_uid || window.sessionStorage.getItem('session_uid')),
      ttclid: Cookies.get('ttclid'),
      ttp: Cookies.get('_ttp'),
    }

    if (!data.session_uid) {
      data.session_uid = 'b-' + (new Date()).getTime() + Math.floor(Math.random() * 1000)
      window.session_uid = data.session_uid
      setSession({lang: i18n.resolvedLanguage})
    }

    dataFetch(
      process.env.REACT_APP_API_URI + 'web-members',
      data,
      (response) => {
        // const response = {"uid":"02d6b0ff-6dae-44dc-9400-68673ef7dab8"}
        identify(response.uid, data.email)
        setStep({...step, page: 'email page', email: data.email}, toAdd)
        navigate(nextUrl)
      }
    )
  }

  function applySuggestion(suggestion) {
    setEmail(suggestion)
    setSuggestions([])
  }

  return (
    <div className={'wrap fixed'}>
      <Top>
        <div className={'row'} style={{height: '40px', justifyContent: 'center'}}>
          <Logo/>
        </div>

        <h1>{t('signIn.h1')}</h1>

        <p className={'notice'} dangerouslySetInnerHTML={{__html: t('signIn.notice')}}/>

        <form onSubmit={handleSubmit} className={'w-100'} style={{position: 'relative'}}>
          <input type={'submit'} className={'d-none'}/>

          <div style={{position: 'absolute', right: -25, top: -42, width: 138, height: 93, pointerEvents: 'none'}}>
            <RiveLoad width={138} height={93} src={'email_cat.riv'} stateMachines="email_cat" image={require(`./assets/img/cat.svg`).default}/>
          </div>
          <input ref={inputRef} id={'email'} type={'email'} placeholder={t('signIn.email')} value={email}
                 className={'form-control mb-4'} required
                 onInput={handleChange}/>

          {suggestions.length > 0 && (
            <ul className="form-control suggestions">
              {suggestions.map((s, index) => (
                <li key={index} onClick={() => applySuggestion(s)}>{s}</li>
              ))}
            </ul>
          )}

          <Button type={'submit'} className={!validateEmail(email) ? 'disabled' : ''} onClick={() => {
            if (!validateEmail(email)) inputRef.current.focus()
          }}>
            {t('signIn.btn')}
            {isLoading && <span className="spinner ml-2"></span>}
          </Button>
        </form>

        <p className={'note md fw-400'}>{t('signIn.info1')}</p>
        <p className={'note md fw-400'}>{t('signIn.info2')}</p>

        <p className={'note md fw-400'}>
          <Trans i18nKey={'signIn.info3'} shouldUnescape={true}>
            Please be aware that your use of Vocal Image is subject to our
            <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'terms'} target={'_blank'}
               className={'link nowrap'}>Terms &amp; Conditions</a>,
            <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'privacy'} target={'_blank'}
               className={'link nowrap'}>Privacy Policy</a>, and
            <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'subscription'} target={'_blank'}
               className={'link nowrap'}>Subscription Policy</a>.
            These documents collectively outline the guidelines and policies governing your interaction with our
            platform.
          </Trans>
        </p>
      </Top>

      <Bottom preload={preload}/>
    </div>
  )
}
